export default function faq() {

    let acc = document.querySelectorAll(".section-faq__accordion");

    if (!acc) return;

    acc.forEach(el => {
        el.addEventListener('click', function () {
            this.classList.toggle("active");
            let panel = this.nextElementSibling;
            panel.classList.toggle("active");
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + 20 + "px";
            }
        })
    })
}